@import "variables.scss";
@import '~bootstrap/scss/bootstrap';
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

:root {
  --darkgrey: #595959;
  --lightgrey: #A6A6A6;
  --blue: #0070C0;
  --darkblue: #172C51;
  --green: #199F56;
  --bg-dark: #5E5E5E;
  --bg-light: #F4F4F4;
  --bg-beige: #FFF7E2;
  --bg-table: #F2F2F2;
  --gold: #BF9000;
  --text-grey: #999999;
  --red: #C63E3E;
  --darkred: #A52727;
}

body {
  margin: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  * {
    box-sizing: border-box;
  }
}

h1 {
  font-size: 36px;
  font-weight: 700;
}

input[type="text"], input[type="password"], textarea {
  border-radius: 6px;
  border: 1px solid var(--lightgrey);
  padding: 8px 16px;
  font-size: 16px;
  outline: none;
  &:active, &:focus {
    outline: none;
    border: 1px solid var(--bg-dark)
  }
}

.error-msg {
  color: var(--darkred);
  font-size: 14px;
}

main {
  background: #F2FDFF;
  max-width: 100vw;
  min-width: 1450px;
  max-height: 100vh;
  height: 100vh;
  min-height: 800px;
  overflow: overlay;
  padding: 25px;
}

::-webkit-scrollbar,
*::-webkit-scrollbar {
  background: #99999920;
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
  border-radius: 2px;
  border: 0 solid transparent;
  background-clip: content-box;
  background: #99999980;
}

::-webkit-scrollbar-thumb:hover,
*::-webkit-scrollbar-thumb:hover {
  background-color: #999999;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.form-check-input:checked {
  background-color: var(--darkblue);
  border-color: var(--darkblue);
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.bold {
  font-weight: 700;
}
.text-grey {
  color: var(--text-grey);
}

.ml-auto {
  margin-left: auto;
}

.flex {
  @include flex(flex-start, flex-start);
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-center {
  @include flex();
}

.login-page {
  @include flex(center, center, column);
  gap: 8px;
  width: 100vw;
  height: 100vh;
  background: #E5ECF9;
  & > * {
    width: 250px;
  }
  h1 {
    text-align: center;
  }
}

button {
  width: 140px;
  height: 44px;
  @include flex();
  border-radius: 4px;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  transition: 200ms ease-in-out;
  &:disabled {
    background: darkgray;
    color: dimgray;
  }
}

.blue-btn {
  background: var(--blue);
  color: white;
  &:hover:not(.status-btn) {
    background: #005FA3;
  }
}
.grey-btn {
  background: var(--lightgrey);
  color: white;
  &:hover:not(.status-btn) {
    background: #868686;
  }
}
.dark-btn {
  background: var(--darkgrey);
  color: white;
  &:hover:not(.status-btn) {
    background: #474747;
  }
}
.green-btn {
  background: var(--green);
  color: white;
  &:hover:not(.status-btn) {
    background: #009443;
  }
}
.red-btn {
  background: var(--red);
  color: white;
  &:hover:not(.status-btn) {
    background: var(--darkred);
  }
}

.status-btn {
  &:hover {
    cursor: default;
  }
}

.logout-button {
  @include flex();
  gap: 4px;
  background: transparent;
  color: var(--text-grey);
  font-weight: 600;
  font-size: 14px;
  height: 30px;
  width: 100px;
  margin: 0 20px 0 auto;
  transition-duration: 0ms;
  &:hover {
    color: var(--darkgrey);
    svg path {
      fill: var(--darkgrey);
    }
  }
}

.toast-container {
  min-width: 200px;
  text-align: center;
  position: absolute;
  bottom: 50px;
  right: 50px;
  .toast-header {
    margin-bottom: 0;
  }
}

.datepicker-wrapper {
  .react-datepicker__view-calendar-icon {
    & > input {
      padding: 8px 16px !important;
    }
    & > svg {
      height: 24px;
      width: 24px;
      position: absolute;
      right: 0;
      path, rect {
        stroke: var(--darkgrey);
        stroke-width: 1px;
      }
    }
  }
}

.navigation-tabs {
  margin-bottom: -1px;
  @include flex(flex-start, flex-start);
  & > div {
    width: 160px;
    height: 48px;
    font-weight: 600;
    color: #FFF;
    background: var(--darkgrey);
    border: 1px solid #222;
    font-size: 20px;
    cursor: pointer;
    @include flex();
    transition-duration: 200ms;
    &:not(:last-of-type) {
      border-right: none;
    }
    border-bottom: none;
  }
  .selected-page {
    background: white;
    color: #222;
  }
}

.page-content {
  background: white;
  border: 1px solid var(--darkgrey);
  padding: 24px;
  height: calc(100vh - 100px);
  min-height: 800px;
  min-width: 1400px;
  overflow: auto;
  scroll-behavior: smooth;
}

ul.pagination {
  margin: 10px auto;
  display: flex;
  justify-content: center;
}

.tab-depth-line {
  display: flex;
  gap: 10px;
  margin-bottom: 24px;
  h1 {
    user-select: none;
    cursor: pointer;
  }
}

.sub-tabs {
  @include flex(center, flex-start);
  font-size: 18px;
  color: #222;
  & > div {
    cursor: pointer;
    user-select: none;
    @include flex();
    padding: 12px 24px;
    border-bottom: 2px solid #FFF7E2;
    background: #F2F2F2;
    transition-duration: 200ms;
    &.selected-tab {
      font-weight: bold;
      border-bottom: 2px solid #222222;
    }
  }
}

.sub-tab-wrapper {
  width: 100%;
  height: 100%;
  margin-top: 20px;
}

.modal-header {
  padding: 12px 20px 8px;
  .h4 {
    font-size: 22px;
    font-weight: 700;
  }
}

.modal-body {
  padding: 24px 16px 16px;
}

.modal-footer {
  justify-content: center;
  border-top: none;
}


// 토리를 구해줘 탭 스타일
.search-filters {
  display: grid;
  grid-template-columns: 500px 680px 160px 1fr;
  gap: 10px;
  & > input {
    max-height: 40px;
  }
  & > div {
    display: flex;
    height: fit-content;
  }
}

.filter-checkbox {
  display: flex;
  font-size: 18px;
  background: #E5ECF950;
  padding: 6px 20px;
  border-radius: 4px;
  & > span {
    min-width: 100px;
    font-weight: bold;
    margin-right: 10px;
  }
  & > div {
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
  }
  label {
    margin-right: 16px;
  }
}

.basic-table {
  text-align: center;
  vertical-align: middle;
  border-bottom: 2px solid var(--text-grey);
  th {
    background: #F4F4F4;
    border-top: 2px solid var(--text-grey) !important;
  }
  td button {
    margin: auto;
  }
  th, td {
    white-space: pre-wrap;
    word-break: break-all;
  }
}

.form-container {
  margin: 40px 0 20px 0;
  display: grid;
  grid-template-columns: 300px 1fr;
  & > div {
    border: 1px solid #A6A6A6;
    @include flex();
    padding: 10px 30px;
    font-size: 18px;
    &:nth-child(even) {
      justify-content: flex-start;
      &:not(:last-child) {
        border-bottom: none;
      }
    }
    &:nth-child(odd) {
      border-right: none;
      &:not(:nth-last-child(2)) {
        border-bottom: none;
      }
      span {
        font-weight: bold;
      }
    }
  }
}

.address-table {
  thead tr {
    th:nth-child(2) {
      width: 130px;
    }
    th:nth-child(3), th:nth-child(4) {
      width: 100px;
    }
    th:nth-child(5) {
      width: 140px;
    }
    th:nth-child(7) {
      width: 200px;
    }
    th:nth-child(9) {
      width: 80px;
    }
  }
}

.type-input {
  gap: 10px;
  input[type="number"] {
    width: 100px;
    border-radius: 6px;
    border: 1px solid var(--lightgrey);
    padding: 8px 16px;
    font-size: 16px;
    outline: none;
    &:active, &:focus {
      outline: none;
      border: 1px solid var(--bg-dark)
    }
  }
}


// 수영장 탭 스타일
.current-status-box {
  margin: 0 0 20px 0;
  display: flex;
  gap: 16px;
  & > div {
    padding: 8px 24px 12px;
    border-radius: 16px;
    border: 2px solid var(--darkblue);
    h2 {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 0;
    }
    span {
      color: var(--text-grey);
    }
  }
}

.pool-booking-status {
  background: var(--bg-light);
  position: relative;
  & > div:first-child {
    display: flex;
    column-gap: 20px;
    align-items: flex-end;
    margin-bottom: 10px;
    span {
      font-weight: bold;
    }
  }
}

.pool-exposure-end-date {
  position: absolute;
  font-size: 11px;
  color: var(--darkgrey) !important;
  font-weight: 400 !important;
  right: 20px;
  top: 34px;
}

.today-booking-status {
  background: var(--bg-beige);
  min-width: 300px;
  & > h2 {
    margin-bottom: 10px !important;
  }
}

.pool-booking-status > div:nth-child(3),
.today-booking-status > div:nth-child(2) {
  @include flex(flex-start, space-between);
  gap: 20px;
  & > * {
    @include flex(center, flex-start, column);
    gap: 4px;
    & > .bold {
      font-size: 40px;
    }
    & > .text-grey {
      &:last-of-type {
        font-size: 18px;
      }
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.closed-date {
  @include flex(center, flex-end);
  gap: 8px;
  margin-top: 17px;
  span {
    width: 78px;
    font-size: 11px;
    color: var(--darkgrey) !important;
    font-weight: 400 !important;
  }
}

.thick-border-top {
  border-top: 2px solid var(--text-grey) !important;
}
.thick-border {
  border-bottom: 2px solid var(--text-grey) !important;
}

.today-visit-table {
  div.guest-number {
    & > div {
      @include flex(center, flex-start);
      gap: 8px;
    }
    span, .number-box {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .number-box {
    border: 1px solid var(--darkblue) !important;
    border-radius: 4px;
    padding: 2px 20px;
    text-align: center;
    margin-right: 25px;
    width: 80px;
    max-width: 80px !important;
  }
  input.number-box {
    padding: 2px 5px 2px 20px;
  }

  span.price-box {
    font-size: 18px;
    font-weight: 600;
  }
}

textarea#memo {
  resize: none;
  width: 100%;
  min-width: 300px;
  font-size: 14px;
}

.today-visit-table, .book-mgt-table {
  th {
    vertical-align: middle;
  }
  td > div {
    width: 100%;
    @include flex(center, flex-start);
    & > :first-child {
      width: 160px;
    }
    button {
      max-height: 34px;
      margin-left: 0;
    }
  }
}

.book-mgt-table {
  thead tr:first-child {
    & > th:nth-child(-n+6):not(:first-child) {
      width: 170px;
    }
  }
  td.status-cell {
    vertical-align: baseline;
    padding-top: 24px;
  }
}

.cancel-reason {
  flex-direction: column !important;
  align-items: flex-start !important;
  span {
    font-weight: 700;
  }
  p {
    font-size: 15px;
    max-width: 400px;
  }

  .cancel-admin-memo {
    width: 100%;
    @include flex(center, flex-start);
    gap: 6px;
    margin: 6px 0;
    padding-top: 6px;
    border-top: 1px dashed lightgrey;
    span:first-of-type {
      margin-right: auto;
    }
    button {
      font-size: 14px;
      height: 26px;
      width: 90px;
      margin-right: 0 !important;
    }
  }

  p.admin-memo {
    padding: 8px 16px;
    background: var(--bg-beige);
    border-radius: 8px;
  }
}

.select-with-input {
  position: relative;
  border-radius: 6px;
  border: 1px solid var(--lightgrey);
  display: flex;

  input, input:focus, input:active {
    border: none;
    outline: none;
  }

  input {
    width: 100%;
  }

  .select-btn {
    @include flex(center, flex-start);
    cursor: pointer;
    width: 130px;
    min-width: 130px;
    padding: 4px 16px 4px 20px;
    border-right: 1px solid var(--lightgrey);
    svg {
      margin-left: auto;
      height: 14px;
      transition-duration: 200ms;
      path {
        fill: var(--text-grey);
      }
    }
  }

  .select-list {
    position: absolute;
    top: 42px;
    z-index: 10;
    width: 130px;
    background: white;
    border-radius: 6px;
    overflow: hidden;
    height: 0;
    border: none;
    transition: height 200ms;
    ul {
      @include no-style-list();
      padding: 0 4px;
    }
    li {
      cursor: pointer;
      padding: 4px 16px;
      &:hover {
        background: var(--bg-beige);
        font-weight: 600;
      }
    }
    &.select-open {
      height: 136px;
      border: 1px solid var(--lightgrey);
    }
  }
}

.booking-search-input {
  width: 510px;
}


.price-table {
  th {
    vertical-align: middle;
  }
  td .date-period, td .use-yn {
    width: 100%;
    padding: 0 20px;
    @include flex(center, flex-start);
  }
  td .use-yn {
    gap: 16px;
    padding: 4px 20px;
  }
  .react-datepicker-wrapper input {
    width: 200px;
  }

  input[type="number"] {
    margin: 0 20px;
    min-width: 200px;
    width: calc(100% - 40px);
    border-radius: 6px;
    border: 1px solid var(--lightgrey);
    padding: 8px 16px;
    font-size: 16px;
    outline: none;
    &:active, &:focus {
      outline: none;
      border: 1px solid var(--bg-dark)
    }
  }

  .form-check:has(input:checked) {
    input, label {
      opacity: 1;
    }
  }

  .disabled-check .form-check  {
    input:disabled, label {
      opacity: 0.5 !important;
    }
  }
}

.new-price-table {
  th {
    background: var(--bg-beige);
  }
}


.event-checkboxes {
  width: 50%;
  min-width: 300px;
  @include flex(flex-start, flex-start, column);
  padding: 0 20px 0 0;
  gap: 6px;
  & > div {
    width: 100%;
  }
}

.event-popup-table {
  margin-top: 30px;
  thead {
    th:first-child {
      width: 80px;
    }
  }
}

.button-cell {
  width: 140px;
  button {
    max-height: 34px;
    max-width: 90px;
  }
}

.event-form {
  input.text-input {
    width: 70%;
    min-width: 400px;
  }
}

.price-tab-wrapper {
  @include flex(flex-start, flex-start, column);
  gap: 30px;
  input:disabled:not([type="checkbox"]) {
    border: 1px solid transparent;
    color: #222;
    background: rgba(229, 236, 249, 0.3137254902);
  }
}


.image-input {
  @include flex(flex-end, flex-start);
  input {
    width: 0;
    height: 0;
    max-width: 0;
    max-height: 0;
    overflow: hidden;
  }
  & > div {
    @include flex(flex-end, flex-start);
    gap: 8px;
  }
  button {
    height: 34px;
  }
}

.form-check-input {
  cursor: pointer;
}


.app-version-edit {
  @include flex(flex-start, flex-start, column);
  max-width: 600px;
  margin: 40px 0 20px;
  gap: 12px;
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding: 24px;
  div {
    width: 100%;
    text-align: center;
    display: grid;
    grid-template-columns: 160px 1fr 1fr;
    span {
      margin-top: 6px;
    }
    button {
      margin-left: auto;
    }
  }
}

.version-edit {
  display: grid;
  grid-template-columns: 90px 300px;
  grid-template-rows: 50px;
  gap: 10px;
  .value-label {
    text-align: end;
    vertical-align: middle;
    margin-top: 8px;
  }
  .react-datepicker-wrapper, input {
    width: 100%;
  }
}